// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '200'
  },  {
    prop: 'user_id',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'update',
    Object: 'value',
    label: '',
    width: '200'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'point',
    label: '',
    Object: 'value',
    width: '200'
  }]
}
