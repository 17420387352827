<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
        <template #topBtn>
          <el-button type="primary" size="small" @click="handleExport">{{$t('button.batchExport')}}</el-button>
          <el-button type="primary" size="small" @click="handleForwards('add')">{{$t('form.members.manualTopUp')}}</el-button>
          <el-button type="primary" size="small" @click="handleForwards('sub')">{{$t('form.members.manualDeduct')}}</el-button>
          <el-button type="primary" size="small" @click="handleForwardsFile">{{$t('form.members.batchUpdatePoints')}}</el-button>
        </template>
      </search-form>
      <create-table :operationColWidth="150" @handleNewTab="handleNewTab($event, 'pointsRecordDetail')" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort">
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getPointRecordsSetup, getPointRecordsList, getMemberPointExport } from '@/services/members'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
    $route (to, from) {
      // console.log(from)
      // if (from.name === 'managerUserOrderView') {
      //   this.$nextTick(() => {
      //     this.search()
      //     this.$refs.createTable.$refs.table.doLayout()
      //   })
      // }
    }
  },
  created () {
    this.queryData(getPointRecordsSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getPointRecordsSetup)
    this.isActive = true
  },
  methods: {
    // 点击操作按钮
    async handleClick (type, data) {
      if (type?.action === 'view') {
        this.$router.push({
          name: 'pointsRecordDetail',
          params: {
            id: data.id.value
          }
        })
      }
    },
    handleForwards (type) {
      this.$router.push({
        name: 'pointsRecordOperatePoint',
        params: {
          name: type
        }
      })
    },
    handleForwardsFile () {
      this.$router.push({
        name: 'pointsRecordUpdatePoint'
      })
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getPointRecordsList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(getMemberPointExport, (data) => {
        this.$router.push({
          name: 'memberPointDownloadCenter',
          params: { data }
        })
      }, this)
    }
  }
}
</script>

<style>

</style>
